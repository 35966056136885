<template>
    <section>
        <div class="container">
            <meb-carousel/>
            <meb-biographie></meb-biographie>
        </div>
    </section>
</template>
<script>
import mebCarousel from '../../../../../components/element/meb/meb-carousel.vue'
import mebBiographie from '../../../../../components/element/meb/meb-biographie.vue'
export default {
    name:'meb-home',
    components:{
        mebBiographie,
        mebCarousel
    }
}
</script>