<template>
  <section>
    <!-- description + dessin -->
    <div class="row mt-5 leaf-background-3">
      <div class="col-md-3"></div>
      <div class="col-md-6 d-flex justify-content-center">
        <div class="card avis m-5">
          <div class="card-body">
            <template v-if="image">
              <picture>
                <source :srcset="image.webpImage" type="image/webp">
                <img
                  class="d-block img-xl rounded-circle mx-auto mb-2"
                  :src="image.originalImage"
                  alt="profile image"
                />
              </picture>
            </template>
            <img
              v-else
              class="d-block img-xl rounded-circle mx-auto mb-2"
              :src="image"
              alt="profile image"
            />
            <div v-if="editable">
              <add-image
                :modal="'cardUserReview' + sectionTitle"
                @saveImage="saveImage($event)"
              />
            </div>
            
            <!-- Contenu texte -->
            <tt-editor v-if="editable" :value="text" @input="changeText($event)" ></tt-editor>
            <span v-else v-html="text"></span>
          </div>
        </div>
      </div>
      <div class="col-md-3"></div>
    </div>
  </section>
</template>
<script>
import addImage from "../../functions/add-image.vue";
import ttEditor from '../tt-editor.vue';
export default {
  name: "meb-biographie",
  components: {
    addImage,
    ttEditor
  },
  data: function () {
    return {
    };
  },
  props: {
    items: null,
    editable: Boolean,
    sectionTitle: String,
    image: {
      type:Object
    },
    text:{
        type: String,
        default:''
    }
  },
  methods: {
    textTypographie(elem, data) {
      if (data["type"] == "size") {
        elem.data["paragraph-font-size"] = data["value"];
      }
      if (data["type"] == "justify") {
        elem.data["paragraph-font-justify"] = data["value"];
      }
      if (data["type"] == "weight") {
        elem.data["paragraph-font-weight"] = data["value"];
      }
      if (data["type"] == "style") {
        elem.data["paragraph-font-style"] = data["value"];
      }
    },
    saveImage(image) {
      this.$emit("change-cur-image", image);
    },
    changeText(payload){
        this.$emit('changeTextMebBiographie',payload)
    }
  },
};
</script>

<style scoped>
.leaf-background-3 {
  position: relative;
  min-height: 30rem;
  background-image: url("../../../assets/images/import/fond-branche-3.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>